import { SCRIPT_STATUS_READY, useScript } from '@bluheadless/ui-logic/src/hooks/use-script/useScript'
import { usePrevious } from '@bluheadless/ui-logic/src/hooks/usePrevious'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useStore } from '@bluheadless/ui-logic/src/providers/store'
import isEqual from 'lodash/isEqual'
import { useCallback, useEffect, useMemo, useState } from 'react'

const StyleGenie = ({ configs: { domain = '', element = 'body', ...configs } } = {}) => {
	const { projectSpecific: { styleGenie: { scriptPath, cssPath, clientKey } = {} } = {} } = useConfig()
	const { code: currentStoreCode, locale } = useStore()
	const [initialized, setInitialized] = useState(false)

	// https://docs.4gift.com/style-genie/integration/web-component-solution/method-and-events#createentrypoint
	const params = useMemo(
		() => (locale && currentStoreCode ? [locale.split('-')[0], currentStoreCode, {}] : null),
		[currentStoreCode, locale]
	)

	const previousParams = usePrevious(params)

	const CSS_URL = domain && cssPath ? `${domain}${cssPath}` : null
	const scriptStatus = useScript({ src: domain && `${domain}${scriptPath}` })

	const loadCss = useCallback(() => {
		if (CSS_URL && !document.querySelector(`link[href="${CSS_URL}"]`)) {
			const link = document.createElement('link')
			link.href = CSS_URL
			link.rel = 'stylesheet'
			link.type = 'text/css'
			document.head.appendChild(link)
		}
	}, [CSS_URL])

	useEffect(() => {
		if (scriptStatus === SCRIPT_STATUS_READY && !initialized) {
			// https://docs.4gift.com/style-genie/integration/web-component-solution/method-and-events#init
			window.StyleGenie.init({ clientKey, element, ...configs })
			window.StyleGenie.createEntryPoint(...params)

			setInitialized(true)
		}
	}, [clientKey, configs, currentStoreCode, element, initialized, loadCss, locale, params, scriptStatus])

	useEffect(() => {
		if (scriptStatus === SCRIPT_STATUS_READY && initialized && !isEqual(params, previousParams)) {
			window.StyleGenie.updateEntryPoint(params)
		}
	}, [initialized, loadCss, params, previousParams, scriptStatus])

	useEffect(() => {
		loadCss()
		return () => {
			if (window.StyleGenie && typeof window.StyleGenie.deleteEntryPoint === 'function') {
				window.StyleGenie.deleteEntryPoint()
				// unload CSS
				if (CSS_URL) {
					const link = document.querySelector(`link[href="${CSS_URL}"]`)
					link && link.remove()
				}
			}
		}
	}, [CSS_URL, loadCss])

	return null
}

export default StyleGenie
